<template>
  <div id="app">
    <div class="view">
      <transition
        enter-active-class="animate__animated animate__fadeInUp "
        leave-active-class="animate__animated animate__fadeOutDown "
      >
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "app"
};
</script>
<style lang="scss">
@import "@/assets/styles/_global.scss";

html {
  margin: 0;
  padding: 0;
  color: #32abc6;
  background: #242222;
  overflow-x: hidden;
}
#app {
  margin: 0;
  padding: 0;
  color: #32abc6;
  background: #242222;
}
.page-view {
  position: absolute;
  max-width: 1920px;
}
</style>
