import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/offer",
    name: "offer",
    component: () => import("../views/Offer.vue")
  },
  {
    path: "/productions",
    name: "Productions",

    component: () => import("../views/Projects.vue")
  },
  {
    path: "/team",
    name: "team",

    component: () => import("../views/Team.vue")
  },
  {
    path: "/partnership",
    name: "partnership",
    component: () => import("../views/Partnership.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("../views/Menu.vue")
  },
  {
    path: "*",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
