<template>
  <section id="scroll">
    <Loader />
    <div>
      <div class="main">
        <div class="top-horizontal-line"></div>
        <h1 class="main-display" v-scrollanimation>
          <div class="pre-logo-vertical-line"></div>

          WE<span class="letter-spacing-0">B </span>
          <img class="logo-img" src="../assets/logo.png" />
          VISIO
          <div class="logo-vertical-line"></div>
          <span class="letter-spacing-0">N</span>
        </h1>
        <div class="bb-accent"></div>

        <div class="introduction-text">
          <span class="introduction-word"> Pozwól nam</span>
          <span class="introduction-word">Zrealizować</span>
          <span class="introduction-word"> Twoją</span>
          <span class="introduction-word">Wizję</span>
        </div>

        <div class="social-media">
          <span
            ><a
              class="social-link"
              href="https://www.facebook.com/WebVision.Art"
              target="_blank"
              ><b-icon
                class="social-icon"
                scale="1.5"
                role="img"
                icon="facebook"
              >
              </b-icon
            ></a>
            <a
              class="social-link"
              href="https://twitter.com/WebvisionA"
              target="_blank"
              ><b-icon
                class="social-icon"
                scale="1.5"
                role="img"
                icon="twitter"
              >
              </b-icon
            ></a>
            <a
              class="social-link"
              href="https://www.instagram.com/webvision.art/"
              target="_blank"
              ><b-icon
                class="social-icon"
                scale="1.5"
                role="img"
                icon="instagram"
              >
              </b-icon
            ></a>
          </span>
        </div>
        <transition
          enter-active-class="animate__animated animate__backInUp animate__slow"
        >
          <div class="bt-accent main-footer" v-if="show">
            <div class="main-footer-left ">
              <MenuBurger />
              <div class="pre-logo-vertical-line-2"></div>
            </div>
            <div class="social-media-screen">
              <span>
                <a
                  class="social-mr social-link"
                  href="https://www.facebook.com/WebVision.Art"
                  target="_blank"
                  >facebook</a
                >
                <a
                  class="social-mr social-link"
                  href="https://twitter.com/WebvisionA"
                  target="_blank"
                >
                  twitter </a
                ><a
                  class=" social-link"
                  href="https://www.instagram.com/webvision.art/"
                  target="_blank"
                >
                  instagram</a
                >
              </span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "@/components/Loader.vue";
import MenuBurger from "@/components/MenuBurger.vue";

export default {
  name: "HelloWorld",
  components: {
    MenuBurger,
    Loader
  },
  data() {
    return {
      show: false,
      prevRoute: null
    };
  },
  methods: {
    loadIntroductionText: function() {
      const element = document.querySelectorAll(".introduction-word");
      for (var i = 0; i <= element.length - 1; i++) {
        element[i].classList.add("loaded-introduction-word");
      }
    },
    pushMenu: function() {
      this.$router.push({ path: "/menu" });
    }
  },

  beforeMount: function() {
    setTimeout(() => this.loadIntroductionText(), 3500);
    setTimeout(() => (this.show = true), 2000);
  },
  created: function() {
    setTimeout(() => window.addEventListener("scroll", this.pushMenu), 1100);
  },
  destroyed: function() {
    window.removeEventListener("scroll", this.pushMenu);
  }
};
</script>

}
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/HelloWorld/_HelloWorld.scss";
@import "@/assets/styles/HelloWorld/responsive/xlarge_D.scss";
@import "@/assets/styles/HelloWorld/responsive/large_D.scss";
@import "@/assets/styles/HelloWorld/responsive/medium_D.scss";
@import "@/assets/styles/HelloWorld/responsive/small_D.scss";
@import "@/assets/styles/HelloWorld/responsive/xsmall_D.scss";
.disappear {
  opacity: 0;
}
</style>
