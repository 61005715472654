import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import ScrollAnimation from "./directives/scrollanimation";
import ScrollAnimation2 from "./directives/scrollanimation2";
import ScrollAnimation3 from "./directives/scrollanimation3";
import ScrollAnimation4 from "./directives/scrollanimation4";
import "animate.css";

Vue.use("animate.css");

Vue.directive("scrollanimation", ScrollAnimation);
Vue.directive("fade", ScrollAnimation2);
Vue.directive("fade-two", ScrollAnimation3);
Vue.directive("fade-three", ScrollAnimation4);

Vue.config.productionTip = false;

Vue.prototype.bus = new Vue();

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
