<template>
  <div v-bind:class="loader_container">
    <div class="loading-screen ">
      <div class="first-tile"></div>
      <div class="first-divider"></div>
      <div class="second-tile"></div>
      <div class="second-divider"></div>
      <div class="third-tile"></div>
      <div class="third-divider"></div>
      <div class="fourth-tile"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader",
  data() {
    return {
      loader_container: "loader-container"
    };
  },
  beforeCreate: function() {
    document.body.classList.add("loadingOverflow");
    setTimeout(() => this.loader(), 4000);
  },
  methods: {
    loader: function() {
      this.loader_container = "loader-container-done";
      document.body.classList.remove("loadingOverflow");
    }
  }
};
</script>

<style lang="scss">
.loadingOverflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>

<style lang="scss" scoped>
.loading-screen {
  height: 100vh;
  width: 100vw;
  position: absolute;

  animation-name: loading-main;
  animation-duration: 3s;
  z-index: 1000;
}
.first-tile {
  height: 60vh;
  width: 50%;
  background-color: #242222;
  animation-name: first-disappear;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}
.first-divider {
  top: 60vh;
  bottom: 35.5vh;
  height: 0.5vh;
  background-color: #32abc6;
  width: 50%;
  animation-name: horizontal;
  animation-duration: 3.5s;
  animation-fill-mode: forwards;
  position: absolute;
}
.second-tile {
  width: 50%;
  height: 40vh;
  top: 61vh;
  position: absolute;
  background-color: #242222;
  top: 60.5%;
  animation: second-disappear 3s forwards;
}
.second-divider {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100vh;
  width: 0.4vw;
  background-color: #32abc6;
  animation: vertical 3s forwards;
}
.third-tile {
  left: 50.4%;
  top: 0;
  position: absolute;
  width: 50vw;
  height: 22vh;
  background-color: #242222;
  animation-name: third-disappear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.third-divider {
  position: absolute;
  left: 50%;
  top: 21vh;
  height: 0.5vh;
  width: 50%;
  background-color: #32abc6;
  animation-name: second-horizontal;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}
.fourth-tile {
  position: absolute;
  background-color: #242222;
  height: 80vh;
  left: 50.4%;
  width: 50%;
  top: 21.5vh;
  animation: fourth-disappear 3.5s forwards;
}
@keyframes fourth-disappear {
  25% {
    transform: rotate(-360deg);
  }
  50% {
    border-radius: 50%;
    width: 40vh;
    height: 40vh;
    top: 40vh;
    transform: rotate(360deg);
  }
  100% {
    border-radius: 100%;
    display: none;
    width: 0;
    height: 0;
    top: 45vh;
  }
}
@keyframes second-disappear {
  0% {
    height: 40vh;
  }
  100% {
    height: 0vh;
    display: none;
    top: 100vh;
  }
}
@keyframes first-disappear {
  50% {
    height: 60vh;
  }
  100% {
    height: 0vh;
    width: 0%;
    margin-left: 50%;
    display: none;
  }
}
@keyframes loading-main {
  35% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes third-disappear {
  50% {
    transform: rotate(0deg);
    border-radius: 0%;
  }
  100% {
    transform: rotate(360deg);
    width: 0vw;
    height: 0vh;
    display: none;
    border-radius: 100%;
  }
}
@keyframes vertical {
  0% {
    height: 100vh;
  }
  100% {
    height: 0vh;
  }
}
@keyframes horizontal {
  0% {
    width: 50%;
  }
  100% {
    width: 0%;
    background-color: transparent;
  }
}
@keyframes second-horizontal {
  0% {
    width: 50%;
  }
  100% {
    width: 0%;
    left: 100%;
    background-color: transparent;
  }
}

.loader-container {
  display: block;
}
.loader-container-done {
  display: none;
}
</style>
