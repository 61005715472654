<template>
  <router-link to="/menu">
    <div
      @mouseover="hover"
      @mouseleave="nothover"
      class="menu-burger MenuBurger-burger"
    >
      <div class="menu-line-st" v-bind:class="nav"></div>
      <div class="menu-line-nd" v-bind:class="nav"></div>
      <div class="menu-line-rd" v-bind:class="nav"></div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MenuBurger",
  data() {
    return {
      nav: ""
    };
  },
  methods: {
    hover: function() {
      setTimeout(() => (this.nav = "nohover"), 10);
    },
    nothover: function() {
      setTimeout(() => (this.nav = ""), 10);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/MenuBurger/_MenuBurger.scss";
@import "@/assets/styles/MenuBurger/responsive/xlarge_D.scss";
@import "@/assets/styles/MenuBurger/responsive/large_D.scss";
@import "@/assets/styles/MenuBurger/responsive/medium_D.scss";
@import "@/assets/styles/MenuBurger/responsive/small_D.scss";
@import "@/assets/styles/MenuBurger/responsive/xsmall_d.scss";
</style>
